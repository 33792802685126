import React, {useEffect, useState} from 'react';
import {Button, Form, FormField, Input} from 'semantic-ui-react';
import QmsmodalComponent from './qmsmodal/qmsmodal.component';
import styles from './qmscontrol.module.css';
import {useLocation} from 'react-router-dom';
import {put} from '../../clients/mobile-backend.client';
import {AxiosResponse} from 'axios';

type QmsControlProps = {
  siteId: string;
};

type QmsControlState = {
  previewOpened: boolean;
  qmsToken: string;
  websiteLink: string;
  isTokenLoaded: boolean;
};

interface QmsTokenResponse {
  id: string;
  storeId: string;
}

const QmsControl: React.FC<QmsControlProps> = (props: QmsControlProps) => {

  const [state, setState] = useState<QmsControlState>({
    previewOpened: false,
    isTokenLoaded: false,
    websiteLink: '',
    qmsToken: ''
  });

  useEffect(() => {
    // Code to execute once on component load
    loadLink();

    // Optionally return a cleanup function
    return () => {
      console.log('Component will unmount');
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const openPreviewModal = () => {
    setState({...state, previewOpened: true});
  };

  const closePreviewModal = () => {
    setState({...state, previewOpened: false});
  };

  const location = useLocation();

  const loadLink = (): void => {
    let siteId = props.siteId;
    const currentUrl = `${window.location.origin}${location.pathname}${location.search}${location.hash}`;
    put<QmsTokenResponse[]>(`/api/v2/store/${siteId}/qmsToken`)
    .then((response: AxiosResponse) => {
      const receivedQmsToken = response.data.id;
      setState({...state,
        qmsToken: receivedQmsToken,
        websiteLink: currentUrl + 'cktv?id=' + receivedQmsToken,
        isTokenLoaded: true});
    });
  };

  const copyToClipBoard = (): void => {
    navigator.clipboard.writeText(state.websiteLink).then(() => {
    }).catch(err => {
      console.error('Error copying text: ', err);
    });
  };

  return (
      <div className={styles.qmsControlContainer}>
        <QmsmodalComponent siteId={props.siteId}
                           isOpened={state.previewOpened}
                           closeModal={closePreviewModal}></QmsmodalComponent>
        <div>
          <span className={styles.linkMessage}>
            Link to QMS Order dashboard for site {props.siteId}:
          </span>
          <Form>
            <FormField>
              <div className={styles.container}>
                <div className={styles.columnFlex2}>
                  <Input loading={!state.isTokenLoaded}
                         className={styles.inputTextColor}
                         disabled
                         placeholder='Loading QMS Link' value={state.websiteLink}/>
                </div>
                <div className={styles.column}>
                  <Button primary onClick={copyToClipBoard}>Copy to clickboard</Button>
                </div>
              </div>
            </FormField>
          </Form>
        </div>
        <Button
            color='blue'
            content='QMS screen preview'
            onClick={openPreviewModal}
        />
        <div className={styles.qmsInstructionContainer}>
          <div className={styles.qmsInstructionTitle}>
            How to login to your QMS account:
          </div>
          <ul className={styles.qmsInstructionList}>
            <li>
              QMS login: <span className={styles.qmsInstructionBold}>{props.siteId}_screen</span>
            </li>
            <li>
              QMS password was delivered by CK SOM owner
            </li>
          </ul>
        </div>
      </div>
  );
};

export default QmsControl;
