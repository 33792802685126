import React from 'react';
import {Icon, Label, Loader} from 'semantic-ui-react';
import styles from './activeorders.module.css';
import {PapOrder} from '../../model/responses/paporder.response';
import MenuItem from './menuitem/menuitem.component';
import {OrdersState} from '../dashboard/dashboard.component';
import {ScrollMenu} from 'react-horizontal-scrolling-menu';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import './hideScrollbar.css';
import {LeftArrow} from './leftArrow.component';
import {RightArrow} from './rightArrow.component';

interface Props {
    siteId: string;
    ordersState: OrdersState;
    getAndSetActivePreorders(siteId: string): void;
    removeOrderFromActiveAndUpcomingOrdersList(id: string): void;
}


export const ActiveOrders = (props: Props) => {

    const getLoader = () => {
        return (<Loader inline={'centered'} active size='tiny' content='Refreshing'/>);
    };

    const getActiveOrders = () => {
        return (<div>
            <div style={{display: 'flex', justifyContent: 'space-between', height: '60px'}}>
                <div></div>
                <div>
                    {props.ordersState.isLoading ? getLoader() : null}
                </div>
                <div className={styles.QueueLabelContainer}>
                    <Label size='large' className={styles.QueueLabel} color='orange'>
                        <Icon name='angle double right'/>{props.ordersState.orders.length} order(s) in queue
                    </Label>
                </div>
            </div>
            <div>
                <ScrollMenu
                    LeftArrow={<LeftArrow/>}
                    RightArrow={<RightArrow/>}
                >
                    {
                        props.ordersState.orders.map((order: PapOrder) => {
                            return <MenuItem order={order}
                                             itemId={order.id}
                                             key={order.id}
                                             siteId={props.siteId}
                                             getAndSetActivePreorders={props.getAndSetActivePreorders}
                                             removeFromList={() => props.removeOrderFromActiveAndUpcomingOrdersList(order.id)}/>;
                        })
                    }
                </ScrollMenu>
            </div>
        </div>);
    };

    const getActiveOrdersOrError = () => {
        const errorPargraph = <p
            style={{color: 'red', textAlign: 'center', fontWeight: 'bold', fontSize: '16px'}}
        >Error occurred during fetching orders.</p>;
        return (
            <React.Fragment>
                {props.ordersState.isError ? errorPargraph : getActiveOrders()}
            </React.Fragment>
        );
    };


    return (
        <div>
            {getActiveOrdersOrError()}
        </div>
    );
};
