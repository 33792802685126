import React, {Component} from 'react';
import {Button, Form, Grid, Header, Icon, Image, Input} from 'semantic-ui-react';
import cklogo from './cklogo-horizontal.png';
import styles from './login.module.css';
import axios, {AxiosResponse} from 'axios';

const browserWindow: any = window || {};
const browserWindowEnv: any = browserWindow['_env_'] || {};
const baseUrl: string = browserWindowEnv.REACT_APP_MB_BASE_URL;
const deviceTokenKey: string = 'deviceToken';

class Login extends Component<any, any> {

  state: any = {
    login: '',
    password: '',
    authorities: [],
    error: false,
    errorMsg: '',
    isLoading: false,
    deviceToken: null,
    showPassword: false,
    siteId: 0
  };

  componentDidMount(): void {
    const deviceToken = new URLSearchParams(window.location.search).get('deviceToken');
    if (deviceToken !== null) {
      localStorage.setItem(deviceTokenKey, deviceToken);
      this.setState({deviceToken: deviceToken});
      this.cleanUpUrl();
    }
  }

  private cleanUpUrl(): void {
    const uri = window.location.toString();
    const index = uri.indexOf('?');
    if (index > 0) {
      const clean_uri = uri.substring(0, index);
      window.history.replaceState({}, document.title, clean_uri);
    }
  }

  private onLoginChange = (event: React.FormEvent<HTMLInputElement>): void => {
    this.setState({login: event.currentTarget.value});
  }

  private onPasswordChange = (event: React.FormEvent<HTMLInputElement>): void => {
    this.setState({password: event.currentTarget.value});
  }

  private extractSiteIdFromLogin = (login: string): number => {
    const siteIds = login.match(/\d+/g);
    if (siteIds) {
      const siteId = siteIds.map(Number);
      return siteId[0];
    } else {
      return 0;
    }
  }

  private isScreenOnly = (authorities: string[]): boolean => {
    for (let i = 0; i < authorities.length; i++) {
      if (authorities[i] === 'SCREEN') {
        return true;
      }
    }
    return false;
  }

  private login = () => {
    this.setState({isLoading: true});

    const base64header = window.btoa(this.state.login + ':' + this.state.password);
    const deviceToken = localStorage.getItem('deviceToken');
    const loginEndpoint = baseUrl + '/api/v2/login';

    const headers = {
      'Authorization': 'Basic ' + base64header,
      'DeviceToken': deviceToken
    };
    axios.post(loginEndpoint, null, {
      headers: headers
    })
    .then((response: AxiosResponse) => {
      localStorage.setItem(this.state.login, base64header);
      localStorage.setItem('authorities', JSON.stringify(response.data.authorities));
      let siteId = this.extractSiteIdFromLogin(this.state.login);
      localStorage.setItem('login', this.state.login);
      localStorage.setItem('siteId', String(siteId));
      let screenOnly = this.isScreenOnly(response.data.authorities);
      localStorage.setItem('screenOnly', String(screenOnly));
      this.setState({error: false, isLoading: false, authorities: [...response.data.authorities], siteId: siteId});
      this.props.handleUserNameSet(this.state.login, this.state.siteId, screenOnly);
    })
    .catch(reason => {
      if (reason.response?.status === 423) {
        this.setState({error: true, isLoading: false, login: '', password: '', authorities: [], errorMsg: 'Your account is locked, please contact Service Desk'});
      } else {
        this.setState({error: true, isLoading: false, login: '', password: '', authorities: [], errorMsg: 'Error occurred during login. Try again.'});
      }
    });
  }

  private toggleShowPassword = () => {
    this.setState({showPassword: !this.state.showPassword});
  }

  render(): any {
    return (
        <Grid>
          <Grid.Column>
            <div className={styles.logo}>
              <Image src={cklogo} alt='CircleK'/>
            </div>
            <div className={styles.form}>
              <Header as='h3'>Site Order Manager</Header>
              {this.state.error ? <p style={{color: 'red'}}>{this.state.errorMsg}</p> : null}
              <Form>
                <div>
                  <Form.Field>
                    <div>
                      <label htmlFor='email'>Username</label>
                    </div>
                    <Input
                        type='text'
                        required
                        onChange={this.onLoginChange}
                        value={this.state.login}
                        placeholder='username'
                    />
                  </Form.Field>
                  <Form.Field>
                    <div>
                      <label htmlFor='password'>Password</label>
                    </div>
                    <Input
                        icon={<Icon name={this.state.showPassword ? 'eye slash' : 'eye'} onClick={this.toggleShowPassword} link/>}
                        type={this.state.showPassword ? 'text' : 'password'}
                        value={this.state.password}
                        onChange={this.onPasswordChange}
                        required
                        id='password'
                        placeholder='password'
                    />
                  </Form.Field>
                </div>
              </Form>
            </div>
            <div className={styles.button}>
              <Button
                  type='button'
                  content='Login'
                  loading={this.state.isLoading}
                  onClick={this.login}
              />
            </div>
          </Grid.Column>
        </Grid>
    );
  }
}


export default Login;
