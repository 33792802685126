import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import {ContentType} from '../model/enum/content.type.enum';
import qs from 'qs';

const browserWindow: any = window || {};
const browserWindowEnv: any = browserWindow['_env_'] || {};

const baseUrl: string = browserWindowEnv.REACT_APP_MB_BASE_URL;

export function get<T>(uri: string, params?: any, headers?: any, responseType?: any): Promise<AxiosResponse<T>> {
  const login = localStorage.getItem('login') || '';

  const options: AxiosRequestConfig = {
    method: 'GET',
    baseURL: baseUrl,
    params: params,
    responseType: responseType,
    headers: {
      'Accept': ContentType.APPLICATION_JSON_CHARSET_UTF8,
      'Authorization': `Basic ${localStorage.getItem(login)}`,
      ...headers
    },
    paramsSerializer: (params) => qs.stringify(params, { encode: false }),
  };

  return axios.get(uri, options);
}

export function getNoBasicAuth<T>(uri: string, params?: any, headers?: any, responseType?: any): Promise<AxiosResponse<T>> {
  const options: AxiosRequestConfig = {
    method: 'GET',
    baseURL: baseUrl,
    params: params,
    responseType: responseType,
    headers: {
      'Accept': ContentType.APPLICATION_JSON_CHARSET_UTF8,
      ...headers
    },
    paramsSerializer: (params) => qs.stringify(params, { encode: false }),
  };

  return axios.get(uri, options);
}

export function getFile<T>(uri: string): Promise<AxiosResponse<T>> {
  const siteId = localStorage.getItem('login') || '';

  const options: AxiosRequestConfig = {
    method: 'GET',
    baseURL: baseUrl,
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': ContentType.APPLICATION_JSON_CHARSET_UTF8,
      'Authorization': `Basic ${localStorage.getItem(siteId)}`
    }
  };

  return axios.get(uri, options);
}

export function put<T>(uri: string, params?: any, body?: any): Promise<AxiosResponse<T>> {
  const siteId = localStorage.getItem('login') || '';

  const options: AxiosRequestConfig = {
    method: 'PUT',
    baseURL: baseUrl,
    params: params,
    headers: {
      'Authorization': `Basic ${localStorage.getItem(siteId)}`,
      'Accept': ContentType.APPLICATION_JSON_CHARSET_UTF8,
      'Content-Type': ContentType.APPLICATION_JSON_CHARSET_UTF8
    },
  };

  return axios.put(uri, body !== null ? JSON.stringify(body) : null, options);
}

export function post<T>(uri: string, data: any, params?: any, headers?: any, responseType?: any): Promise<AxiosResponse<T>> {
  const siteId = localStorage.getItem('login') || '';

  const options: AxiosRequestConfig = {
    method: 'POST',
    baseURL: baseUrl,
    params: params,
    responseType: responseType,
    headers: {
      'Authorization': `Basic ${localStorage.getItem(siteId)}`,
      ...headers
    }
  };

  return axios.post(uri, data, options);
}

export function postMultipart<T>(uri: string, file: any, params?: any, responseType?: any): Promise<AxiosResponse<T>> {
  const siteId = localStorage.getItem('login') || '';

  const formData = new FormData();
  formData.append('file', file);

  const options: AxiosRequestConfig = {
    method: 'POST',
    baseURL: baseUrl,
    params: params,
    responseType: responseType,
    headers: {
      'Authorization': `Basic ${localStorage.getItem(siteId)}`,
      'Content-Type': ContentType.MULTIPART_FORM_DATA
    },
  };

  return axios.post(uri, formData, options);
}
