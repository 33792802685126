import React, {useEffect, useState} from 'react';
import styles from './queue.module.css';
import parentStyles from '../qmsscreen.module.css';
import {Icon} from 'semantic-ui-react';
import {get, getNoBasicAuth} from '../../../clients/mobile-backend.client';
import {PapOrderResponse} from '../../../model/responses/paporder.response';
import {AxiosResponse} from 'axios';
import OrderBlock from './orderblock/orderblock.component';
import {useLocation} from 'react-router-dom';
import FadeOutControllers from '../fadeOutControlers/fadeoutcontrollers.component';

type QueueComponentProps = {
  siteId: string;
  isNested: boolean;
};

type QueueComponentState = {
  waitingNumbers: string[];
  readyNumbers: string[];
  qmsToken: string;
};

const QueueComponent: React.FC<QueueComponentProps> = (props: QueueComponentProps) => {
  // Initialize state using useState hook
  const location = useLocation();

  // Function to get query parameter value by name
  const getQueryParam = (name : string) => {
    return new URLSearchParams(location.search).get(name);
  };

  const qmsToken = getQueryParam('id');

  const [state, setState] = useState<QueueComponentState>({
    waitingNumbers: [],
    readyNumbers: [],
    qmsToken: ''
  });

  //on load
  useEffect(() => {
    const intervalId = setInterval(loadOrders, 5000);

    // Clean up the interval when the component is unmounted
    return () => {
      clearInterval(intervalId);
    };
  });

  const loadOrders = (): void => {
    if (qmsToken) {
      loadOrderIdsByQmsToken();
    } else {
      loadOrderIdsByBasicAuth();
    }
  };

  const sortOrdersByOrderReadyTime = (aOrder: any, bOrder: any) => {
    const aDate = new Date(aOrder.readyDate);
    const bDate = new Date(bOrder.readyDate);
    if (aDate && bDate) {
      const aDateValue = aDate.valueOf();
      const bDateValue = bDate.valueOf();
      return aDateValue - bDateValue;
    } else if (!aDate && !bDate) {
      return 0;
    } else if (aDate) {
      return 1;
    } else {
      return -1;
    }
  };

  const sortOrdersByCreateDate = (a: any, b: any) => {
    const aDate = new Date(a.createDate);
    const bDate = new Date(b.createDate);
    const aDateValue = aDate.valueOf();
    const bDateValue = bDate.valueOf();
    return aDateValue - bDateValue;
  };

  const loadOrderIdsByBasicAuth = (): void => {
    let siteId = props.siteId;
    get<PapOrderResponse[]>(`/api/v2/orders/active/store/${siteId}`)
    .then((response: AxiosResponse) => {
      let waitingArrObj = response.data.filter((order : any) => isWaiting(order))
        .sort(sortOrdersByCreateDate);
      let waitingArr = waitingArrObj
        .map((order : any) => order.checkNumber);
      let readyArr = response.data.filter((order : any) => isReady(order))
        .sort(sortOrdersByOrderReadyTime)
        .map((order : any) => order.checkNumber);

      setState({...state, waitingNumbers: waitingArr, readyNumbers: readyArr});
    });
  };

  const loadOrderIdsByQmsToken = (): void => {
    getNoBasicAuth<PapOrderResponse[]>(`/api/v2/orders/active/qms?id=${qmsToken}`)
    .then((response: AxiosResponse) => {
      let waitingArrObj = response.data.filter((order : any) => isWaiting(order))
      .sort(sortOrdersByCreateDate);
      let waitingArr = waitingArrObj
      .map((order : any) => order.checkNumber);
      let readyArr = response.data.filter((order : any) => isReady(order))
      .sort(sortOrdersByOrderReadyTime)
      .map((order : any) => order.checkNumber);
      setState({...state, waitingNumbers: waitingArr, readyNumbers: readyArr});
    });
  };

  const isWaiting = (order : any): boolean => {
    return order.status === 'IN_PROGRESS';
  };

  const isReady = (order : any): boolean => {
    return order.status === 'READY';
  };

  const showLogoutButton = () : boolean => {
    return !qmsToken && !props.isNested;
  };

  return (
      <div className={`${parentStyles.column} ${parentStyles.flex1}`}>
        {showLogoutButton() ? <FadeOutControllers/> : undefined}
        <div className={`${parentStyles.container}`}>
          <div className={`${parentStyles.column} ${parentStyles.flex1} ${styles.oneSideContainer}`} style={{backgroundColor: '#f8f8f8'}}>
            <div className={`${styles.orderTypeHeader} ${styles.waitingHeader}`}>
              <Icon name='refresh'/> Preparing
            </div>
            <div className='ui divider'></div>
            <div className={`${styles.twoColumnNumberList}`}>
              {state.waitingNumbers.map((item, index) => (
                  <OrderBlock itemId={item} key={index}/>
              ))}
            </div>
          </div>
          <div className={`${parentStyles.column} ${parentStyles.flex1} ${styles.oneSideContainer}`} style={{backgroundColor: '#ed8b00'}}>
            <div className={`${styles.orderTypeHeader} ${styles.readyHeader}`}>
              <Icon name='shopping basket'/>Ready
            </div>
            <div className='ui divider'></div>
            <div className={`${styles.twoColumnNumberList}`}>
              {state.readyNumbers.map((item, index) => (
                  <OrderBlock itemId={item} key={index}/>
              ))}
            </div>
          </div>
        </div>
      </div>
  );
};

export default QueueComponent;
