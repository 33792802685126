// @ts-ignore
import newOrderSound from './ui_new_order.mp3';
// @ts-ignore
import serverOfflineSound from './ui_error.mp3';
import {Howl} from 'howler';

const SERVER_OFFLINE_ALERT_DURATION_SEC = 30;

class SoundNotificationsV2 {

  static newOrderNotificationSound: HTMLAudioElement = new Audio();
  static serverOfflineNotificationSound: Howl = new Howl({
    src: [serverOfflineSound],
    html5: false,
    loop: true,
    onplayerror: (soundId, error) => {
      console.error('Cannot play server offline alert');
      console.error(error);
    }
  }).load();
  static playNewOrderIndicator: boolean = false;
  static playServerOfflineIndicator: boolean = false;
  static serverOfflineSoundNotificationHandler?: number;
  static currentServerOfflineSoundRepeat: number = 0;

  static isInitialized: boolean = false;

  static initializeAudioFiles = () => {
    SoundNotificationsV2.newOrderNotificationSound.src = newOrderSound;
    SoundNotificationsV2.serverOfflineNotificationSound.play();
    SoundNotificationsV2.serverOfflineNotificationSound.mute(true);
    SoundNotificationsV2.isInitialized = true;
  }

  static playNewOrderSound = () => {
    if (SoundNotificationsV2.playNewOrderIndicator) {
      return;
    }
    SoundNotificationsV2.playNewOrderIndicator = true;
    SoundNotificationsV2.newOrderNotificationSound.src = newOrderSound;
    SoundNotificationsV2.newOrderNotificationSound.addEventListener('ended', SoundNotificationsV2.replayNewOrderSoundOnEndCallback, false);
    SoundNotificationsV2.newOrderNotificationSound.play();
  }

  static playServerOfflineSoundWithDelay = () => {
    if (SoundNotificationsV2.isInitialized) {
      console.log('playServerOfflineSoundWithDelay');
      if (!SoundNotificationsV2.playServerOfflineIndicator) {
        console.log('playServerOfflineSoundWithDelay played');
        SoundNotificationsV2.playServerOfflineIndicator = true;
        SoundNotificationsV2.serverOfflineNotificationSound.mute(false);
        setTimeout(() => {
          SoundNotificationsV2.serverOfflineNotificationSound.mute(true);
          console.log('Muted after 30 sec');
        }, SERVER_OFFLINE_ALERT_DURATION_SEC * 1000);
      } else {
        console.log('playServerOfflineSoundWithDelay did not play');
      }
    }
  }

  static pauseNewOrderSound = () => {
    SoundNotificationsV2.newOrderNotificationSound.removeEventListener('ended', SoundNotificationsV2.replayNewOrderSoundOnEndCallback);
    SoundNotificationsV2.newOrderNotificationSound.pause();
    SoundNotificationsV2.playNewOrderIndicator = false;
  }

  static pauseServerOfflineSound = () => {
    console.log('pauseServerOfflineSound');
    if (SoundNotificationsV2.playServerOfflineIndicator) {
      SoundNotificationsV2.serverOfflineNotificationSound.mute(true);
    }
    SoundNotificationsV2.playServerOfflineIndicator = false;
    SoundNotificationsV2.currentServerOfflineSoundRepeat = 0;
    SoundNotificationsV2.serverOfflineSoundNotificationHandler = undefined;
  }

  static replayNewOrderSoundOnEndCallback = () => {
    SoundNotificationsV2.newOrderNotificationSound.currentTime = 0;
    setTimeout(() => SoundNotificationsV2.playNewOrderIndicator && SoundNotificationsV2.newOrderNotificationSound.play(), 600);
  }
}

export default SoundNotificationsV2;
