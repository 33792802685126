import React from 'react';
import './layout.component.css';
import Login from '../login/login.component';
import Dashboard from '../dashboard/dashboard.component';
import {FooterComponent} from '../footer/footer.component';
import QmsScreen from '../qmsscreen/qmsscreen.component';

class Layout extends React.Component {

  state: any = {
    username: null,
    siteId: '',
    screenOnly: false
  };

  setUserDetails = (userName: string, siteId: string, isScreenOnly: boolean) => {
    this.setState({username: userName, siteId: siteId, screenOnly: isScreenOnly});
  }

  componentDidMount(): void {
    const siteId = localStorage.getItem('siteId');
    const login = localStorage.getItem('login');
    const screenOnly = localStorage.getItem('screenOnly') === 'true';
    if (siteId !== null && login !== null) {
      this.setState({siteId: siteId, username: login});
    } else if (siteId !== null) {
      this.setState({siteId: siteId});
    } else if (login !== null) {
      this.setState({username: login});
    }
    if (screenOnly) {
      this.setState({screenOnly: screenOnly});
    }
  }

  render(): any {
    return (
        <React.Fragment>
          {this.state.username === null ?
              <Login handleUserNameSet={this.setUserDetails}/> :
              !this.state.screenOnly ?
                  <Dashboard siteId={this.state.siteId} userName={this.state.username}/> :
                  <QmsScreen siteId={this.state.siteId} isNested={false}/>
          }
          {
            !this.state.screenOnly ?
                <FooterComponent/>
                :
                undefined
          }

        </React.Fragment>
    );
  }

}

export default Layout;
