import React, {useEffect, useState} from 'react';
import styles from './orderblock.module.css';

type QueueBlockProps = {
  itemId: string;
};

type QueueBlockState = {
  isVisible: boolean;
};

const OrderBlock: React.FC<QueueBlockProps> = (props: QueueBlockProps) => {
  // Initialize state using useState hook
  const [state, setState] = useState<QueueBlockState>({
    isVisible: false
  });

  useEffect(() => {
    // Trigger the fade-in animation after the component mounts
    setState({isVisible: true});
  }, []);

  return (
      // <div>
      //   <div className={`${styles.orderLabel}` + ` ${styles.fadeIn}` + ` ${state.isVisible ? styles.visible : ''}`}>
      //     {props.itemId}
      //   </div>
      // </div>
      <div hidden={!state.isVisible} className={`${styles.animationContainer}`}>
        <div className={`${styles.animationItem}`}>
          {props.itemId}
        </div>
      </div>
  );
};

export default OrderBlock;
